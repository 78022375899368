/*
 * @Author: wankong
 * @Date: 2021-10-04 18:19:43
 * @LastEditTime: 2021-10-04 18:21:32
 */
import Vue from 'vue'

Vue.filter('timeFormatter', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.replace("T", " ").replace(".000+00:00", " ")
})