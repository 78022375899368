<!--
 * @Author: wankong
 * @Date: 2021-09-10 17:47:23
 * @LastEditTime: 2021-10-22 10:46:21
-->
<template>
  <!-- Todo -->
  <MMCard title="ToDo">
    <template slot="content">
      <audio controls="controls">
        <source :src="musicSrc" type="audio/mp3" />
      </audio>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";
export default {
  components: {
    MMCard,
  },
  name: "Todo",
  data() {
    return {
      musicSrc:  this.$store.state.config.web_music,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="less" scoped>
video {
  width: 100%;
}
</style>