<!--
 * @Author: wankong
 * @Date: 2021-10-04 22:41:19
 * @LastEditTime: 2021-10-04 22:49:30
-->
<template>
  <MMCard :showTitle="false">
    <template slot="content">
      <p>
        {{ tips }}
      </p>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card";

export default {
  components: {
    MMCard,
  },
  props: {
    tips: {
      type: String,
      default: "这里什么都没有...",
      require: false,
    },
  },
  name: "NoItem",
};
</script>
<style lang="less" scoped>
p {
  text-align: center;
}
</style>
