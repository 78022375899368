<!--
 * @Author: wankong
 * @Date: 2021-09-09 21:02:49
 * @LastEditTime: 2021-10-18 22:00:35
-->
<template>
  <!-- 站点的标签列表 -->
  <MMCard title="标签">
    <template slot="content">
      <TagList :tags="tags" />
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";

import TagList from "@/components/common/TagList";
export default {
  components: {
    MMCard,
    TagList,
  },
  name: "Tags",
  data() {
    return {
      tags: [],
    };
  },
  methods: {
    async GetTagsList() {
      const { data: res } = await this.$http.get("tags");
      this.tags = res.data;
    },
  },
  created() {
    this.GetTagsList();
  },
};
</script>
<style lang="less" scoped>
.tag_list {
  text-indent: 0;
  width: 100%;
  margin-bottom: 10px;
  .tag {
    padding: 4px 8px;
    background-color: #19b5fe;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    margin: 3px 5px 3px 0;
    border-radius: 10px;
    display: inline-block;
    margin-bottom: 6px;
  }
  .tag:nth-child(5n) {
    background-color: #4a4a4a;
  }
  .tag:nth-child(5n + 1) {
    background-color: #ff5e5c;
  }
  .tag:nth-child(5n + 2) {
    background-color: #ffbb50;
  }
  .tag:nth-child(5n + 3) {
    background-color: #1ac756;
  }
  .tag:nth-child(5n + 4) {
    background-color: #19b5fe;
  }
  .tag:hover {
    background-color: #1b1b1b;
    color: #fff;
  }
}
</style>
