<template>
  <!-- 备案信息等 -->
  <MMCard :showTitle="false">
    <template slot="content">
      <div class="art">
        <TagList :tags="art.tags" />
        <div class="img_box" @click="GoArticle(art.id)">
          <img :src="art.image" alt="" />
          <h4>{{ art.title }}</h4>
        </div>
        <p>{{ art.content | contentFormatter }}</p>
        <p class="info">
          <span class="auth"
            ><i class="bi bi-person"></i> {{ art.author.nickname }}</span
          >
          <span class="cate"
            ><i class="bi bi-archive"></i> {{ art.cate.catename }} </span
          ><span class="time"
            ><i class="bi bi-alarm"></i>
            {{ art.lasttime | timeFormatter }}</span
          >
        </p>
      </div>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card";

import TagList from "@/components/common/TagList";

export default {
  props: {
    art: {
      type: Object,
    },
  },
  components: {
    MMCard,
    TagList,
  },
  name: "ArtItem",
  data() {
    return {};
  },

  filters: {
    contentFormatter: (val) => {
      if (!val) {
        return "";
      } else {
        var str = val
          .replace(/(\*\*|__)(.*?)(\*\*|__)/g, "") //全局匹配内粗体
          .replace(/!\[[\s\S]*?\]\([\s\S]*?\)/g, "") //全局匹配图片
          .replace(/\[[\s\S]*?\]\([\s\S]*?\)/g, "") //全局匹配连接
          .replace(/<\/?.+?\/?>/g, "") //全局匹配内html标签
          .replace(/(\*)(.*?)(\*)/g, "") //全局匹配内联代码块
          .replace(/`{1,2}[^`](.*?)`{1,2}/g, "") //全局匹配内联代码块
          .replace(/```([\s\S]*?)```[\s]*/g, "") //全局匹配代码块
          .replace(/[\s]*[0-9]+\.(.*)/g, "") //全局匹配有序列表
          .replace(/(#+)/g, "") //全局匹配标题
          .replace(/(>+)(.*)/g, "") //全局匹配摘要
          .replace(/\r\n/g, "") //全局匹配换行
          .replace(/\n/g, "") //全局匹配换行
          .replace(/\s/g, ""); //全局匹配空字符;
        return str.slice(0, 155);
      }
    },
  },
  methods: {
    GoArticle(artid) {
      this.$router.push({
        path: `/article/${artid}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.art {
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;

  .tags {
    text-indent: 0;
    width: 100%;
    margin-bottom: 10px;
    a {
      padding: 4px 8px;
      background-color: #19b5fe;
      color: #fff;
      font-size: 16px;
      line-height: 1;
      font-weight: 400;
      margin: 3px 10px 10px 0;
      border-radius: 10px;
      display: inline-block;
    }
    a:nth-child(5n) {
      background-color: #4a4a4a;
    }
    a:nth-child(5n + 1) {
      background-color: #ff5e5c;
    }
    a:nth-child(5n + 2) {
      background-color: #ffbb50;
    }
    a:nth-child(5n + 3) {
      background-color: #1ac756;
    }
    a:nth-child(5n + 4) {
      background-color: #19b5fe;
    }
    a:hover {
      background-color: #1b1b1b;
      color: #fff;
    }
  }
  .img_box {
    width: 100%;
    position: relative;
    cursor: pointer;

    img {
      border-radius: 10px;
    }
    margin-bottom: 10px;

    h4 {
      border-radius: 0 0 0 10px;
      position: absolute;
      padding: 1rem;
      bottom: 0;
      left: 0;
      font-size: 1.5rem;
      line-height: 1.5rem;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
      cursor: default;
      span {
        cursor: text;
      }
    }
  }

  p {
    text-indent: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .info {
    text-indent: 0;
    margin-top: 10px;
    span {
      padding: 4px 0;
      line-height: 1rem;
      margin-right: 20px;
    }
  }
}
@media screen and (min-width: 768px) {
  .img_box {
    height: 360px;
  }
}

@media screen and (max-width: 768px) {
  .img_box {
    height: 200px;
  }
  h4 {
    font-size: 1.3rem !important;
  }
  p {
    font-size: 0.9rem !important;
  }
  .info {
    display: none !important;
  }
}
</style>
