<!--
 * @Author: wankong
 * @Date: 2021-09-19 10:20:44
 * @LastEditTime: 2021-10-05 11:37:57
-->
<template>
  <!-- 搜索框 -->
  <MMCard title="搜索一下">
    <template slot="content">
      <div class="content">
        <input
          type="text"
          v-model="keyword"
          class="key_input"
          placeholder="搜你想搜"
          maxlength="30"
        />
        <button @click="search()" class="sear_btn">走一个</button>
      </div>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";
export default {
  components: {
    MMCard,
  },
  name: "Tips",
  data() {
    return {
      keyword: "",
    };
  },
  methods: {
    search() {
      this.$router.push({ path: "/", query: { keyword: this.keyword } });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .key_input {
    height: 2rem;
    border: none;
    background: #ffffff;
    width: 60%;
    padding-left: 1rem;
    border-radius: 10px;
    color: #778899;
  }
  .key_input:focus-visible {
    border-radius: 10px;
    outline: none;
  }

  .sear_btn {
    border: none;
    height: 2rem;
    background: none;
    border-radius: 30px;
    background: #fff;
    color: #ff5e5c;
    line-height: 2rem;
    width: 30%;
    cursor: pointer;
  }
  .sear_btn:hover {
    background: #ff5e5c;
    color: #fff;
  }
}
</style>
