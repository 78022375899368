<template>
  <div class="top">
    <div class="center">
      <div class="user">
        <img :class="isLinks" :src="userInfo.img" />
        <h2 class="nickname">{{ userInfo.nickname }}</h2>
      </div>
      <div class="motto">
        <p class="content web-font">{{ userInfo.motto }}</p>
      </div>
    </div>
    <div class="links">
      <ul>
        <li v-for="(relaItem, index) in relation" :key="relaItem.name">
          <template v-if="relaItem.img">
            <svg
              @mouseenter="linksEnter(index)"
              @mouseleave="linksLeave"
              :title="relaItem.name"
              class="icon show_qrcode"
              aria-hidden="true"
            >
              <use :xlink:href="'#' + relaItem.icon" />
            </svg>
          </template>
          <template v-else>
            <a :title="relaItem.name" :href="relaItem.url" target="_blank">
              <svg class="icon" aria-hidden="true">
                <use :xlink:href="'#' + relaItem.icon" />
              </svg>
            </a>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",
  data() {
    return {
      userInfo: {
        img: "http://img.mmifx.com/202110290945265?qinniu",
        nickname: "晚空",
        motto: "只有你也想见我的时候，我们的相遇才有意义。",
      },
      relation: [
        {
          id: 1,
          name: "QQ",
          icon: "icon-QQ1",
          url: "http://wpa.qq.com/msgrd?v=3&uin=1549072621&site=qq&menu=yes",
          img: "",
        },
        {
          id: 2,
          name: "WeiXin",
          icon: "icon-weixin",
          url: "",
          img: "//img.mmifx.com/pic/20210904203213.png?qiniu",
        },
        {
          id: 3,
          name: "miniapp",
          icon: "icon-xiaochengxu",
          url: "",
          img: "http://img.mmifx.com/pic/20210904203407.png?qiniu",
        },
        {
          id: 4,
          name: "Gitee",
          icon: "icon-logo-gitee-g-red",
          url: "https://gitee.com/mmifx",
          img: "",
        },
        {
          id: 5,
          name: "GitHub",
          icon: "icon-GitHub1",
          url: "https://github.com/wan-kong",
          img: "",
        },
        {
          id: 6,
          name: "Mail",
          icon: "icon-youxiang",
          url: "http://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&email=_JWRlp_VnZaBlLiJidabl5U",
          img: "",
        },
      ],
      tempimg: "",
      isLinks: "",
    };
  },
  methods: {
    linksEnter(itemid) {
      this.tempimg = this.userInfo.img;
      this.userInfo.img = this.relation[itemid].img;
      this.isLinks = "islinks";
    },
    linksLeave() {
      this.userInfo.img = this.tempimg;
      this.isLinks = "";
    },
  },
  mounted() {
    //   添加iconfont的js symbol 图标需要的资源
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//at.alicdn.com/t/font_1796152_2d804q3uu0v.js";
    document.body.appendChild(script);
  },
};
</script>

<style lang="less" scoped>
.top {
  width: 100%;
  box-sizing: border-box;
  height: 80vh;
  background: linear-gradient(0deg, #9796f0, #fbc7d4);
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  .center {
    width: 100%;
    text-align: center;
    .user {
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        transition: all ease-in-out 1s;
        animation: userImg 1s linear;
      }
      .islinks {
        border-radius: 20px;
      }
      img:hover {
        transform: rotate(360deg);
        filter: brightness(1.1) contrast(1.2) saturate(1.5);
      }
      .nickname {
        margin: 10px;
        line-height: 2rem;
        letter-spacing: 0.5rem;
        cursor: default;
        color: #daf3ef;
        user-select: none;
      }
    }
    .motto {
      margin-top: 20px;
      display: inline-block;
      .content {
        font-size: 25px;
        text-decoration: dashed;
        color: #fff;
        margin-bottom: 6px;
      }
      padding-bottom: 2px;
    }
  }
  .links {
    width: 100%;
    text-align: center;
    ul {
      margin-top: 10px;
      height: 100%;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        margin: 10px;
        padding: 2px;
        border-radius: 50%;
        .icon {
          font-size: 30px;
          box-shadow: 0px 12px 18px 2px rgba(142, 151, 242, 0.5);
        }
        //   设置二维码显示在logo上方（弃用）
        // img {
        //   display: none;
        //   width: 200px;
        //   height: 200px;
        //   border-radius: 20px;
        //   transition: all ease-in-out 5s;
        // }
      }
      //   设置二维码显示在logo上方（弃用）
      //   li:hover {
      //     background-color: #fff;
      //     position: relative;
      //     img {
      //       display: block;
      //       position: absolute;
      //       top: -220px;
      //       left: -80px;
      //     }
      //   }
    }
  }
}
@keyframes userImg {
  0% {
    filter: none;
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
    filter: brightness(1.1) contrast(1.2) saturate(1.5);
  }
  100% {
    filter: none;
    transform: rotate(360deg);
  }
}

@media screen and(max-width:768px) {
  .user {
    img {
      width: 100px !important;
      height: 100px !important;
    }
    .nickname {
      font-size: 1.3rem;
    }
  }
  .motto {
    .content {
      font-size: 20px !important;
    }
  }
}
</style>