<!--
 * @Author: wankong
 * @Desc: 
 * @Date: 2021-09-19 10:14:04
 * @LastEditTime: 2021-10-18 22:50:46
-->
<template>
  <!-- 备案信息等 -->
  <MMCard :showTitle="false">
    <template slot="content">
      <div class="tips">
        <i class="bi bi-volume-up" style="font-size: 1.2rem"></i>
        <span>{{ content }}</span>
      </div></template
    >
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";
export default {
  props: {
    content: String,
  },
  components: {
    MMCard,
  },
  name: "Tips",
};
</script>
<style lang="less" scoped>
.tips {
  width: 100%;
  display: flex;
  span {
    margin-left: 10px;
  }
}
</style>
