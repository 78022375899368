// 随机推荐组件

<template>
  <div class="random">
    <div class="container">
      <div class="title_box">
        <div class="title">
          <!-- 随机推荐的logo -->
          <svg
            t="1630768829082"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5507"
            width="128"
            height="128"
          >
            <path
              d="M540.3648 400.5376L512 343.04l-28.3648 57.4976a45.9776 45.9776 0 0 1-34.7136 25.1904l-63.4368 9.216 45.9264 44.7488a46.1568 46.1568 0 0 1 13.2608 40.8064L433.8176 583.68l56.7296-29.8496a45.99296 45.99296 0 0 1 21.4528-5.2736c7.3728 0 14.7456 1.7408 21.4528 5.2736l56.7296 29.8496-10.8544-63.1808c-2.56-14.9504 2.4064-30.208 13.2608-40.8064l45.8752-44.7488-63.4368-9.216a46.21824 46.21824 0 0 1-34.6624-25.1904z"
              fill="#9FC6FF"
              p-id="5508"
            ></path>
            <path
              d="M829.7472 118.4256H198.3488c-28.2112 0-51.2 22.9888-51.2 51.2v707.5328c0 17.152 8.4992 33.0752 22.784 42.5984 14.2848 9.5232 32.256 11.264 48.0768 4.6592l290.0992-120.7808c2.5088-1.024 5.3248-1.024 7.8336 0l294.3488 121.088c6.2976 2.56 12.9024 3.84 19.5072 3.84 9.984 0 19.9168-2.9184 28.4672-8.6528a51.07712 51.07712 0 0 0 22.6816-42.5472V169.6256c0-28.2624-22.9376-51.2-51.2-51.2zM681.6768 464.384l-51.4048 50.1248 12.1344 70.7584c2.9696 17.408-4.0448 34.7136-18.3296 45.056-14.2848 10.3936-32.8704 11.7248-48.5376 3.4816L512 600.4224l-63.5392 33.3824c-6.8096 3.584-14.1824 5.3248-21.504 5.3248-9.5232 0-18.944-2.9696-27.0336-8.8576a45.83424 45.83424 0 0 1-18.3296-45.056l12.1344-70.7584-51.4048-50.0736c-12.6464-12.3392-17.1008-30.4128-11.6736-47.2576a45.89056 45.89056 0 0 1 37.2224-31.3856l71.0656-10.3424 31.744-64.3584c7.8336-15.8208 23.6544-25.7024 41.3184-25.7024a45.7728 45.7728 0 0 1 41.3184 25.7024l31.744 64.3584 71.0656 10.3424c17.5104 2.56 31.744 14.5408 37.2224 31.3856a45.9264 45.9264 0 0 1-11.6736 47.2576z"
              fill="#9FC6FF"
              p-id="5509"
            ></path>
          </svg>
          随机推荐
        </div>
        <span class="change" title="换一组" @click="GetRandom"
          ><svg
            t="1632449194383"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2970"
            width="200"
            height="200"
          >
            <path
              d="M0 0h1024v1024H0V0z"
              fill="#202425"
              opacity=".01"
              p-id="2971"
            ></path>
            <path
              d="M733.764267 511.931733l-135.816534-135.816533a17.066667 17.066667 0 0 0-24.132266 0l-69.700267 69.7344a17.066667 17.066667 0 0 1-29.149867-12.0832V85.333333a17.066667 17.066667 0 0 1 17.066667-17.066666h348.433067a17.066667 17.066667 0 0 1 12.0832 29.149866l-69.7344 69.700267a17.066667 17.066667 0 0 0 0 24.132267L881.664 290.133333c92.433067 110.933333 86.562133 256.034133 59.938133 339.387734-21.674667 67.8912-84.650667 143.4624-137.216 189.1328-11.605333 10.103467-28.4672-0.580267-26.146133-15.803734 4.3008-27.921067 9.3184-68.437333 12.049067-117.384533 5.3248-95.709867-21.538133-136.772267-56.5248-173.499733z"
              fill="#FFAA44"
              p-id="2972"
            ></path>
            <path
              d="M290.235733 511.931733l135.816534 135.850667a17.066667 17.066667 0 0 0 24.132266 0l69.700267-69.7344a17.066667 17.066667 0 0 1 29.149867 12.0832v348.398933a17.066667 17.066667 0 0 1-17.066667 17.066667H183.534933a17.066667 17.066667 0 0 1-12.049066-29.115733L241.186133 856.746667a17.066667 17.066667 0 0 0 0-24.132267L142.336 733.764267C49.902933 622.830933 55.739733 477.866667 82.397867 394.410667c21.7088-67.9936 84.650667-143.530667 137.216-189.166934 11.605333-10.103467 28.4672 0.6144 26.146133 15.803734a1231.121067 1231.121067 0 0 0-12.049067 117.384533c-5.3248 95.744 21.538133 136.772267 56.5248 173.499733z"
              fill="#11AA66"
              p-id="2973"
            ></path></svg
        ></span>
      </div>
    </div>
    <div class="list">
      <ul>
        <li v-for="(item, index) in randList" :key="index">
          <div
            @click="Gopage(item)"
            class="rand_box"
            :style="'background-image:url(' + item.image + ')'"
          >
            <h4 class="title">{{ item.title }}</h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Random",
  data() {
    return {
      randList: [],
    };
  },
  methods: {
    async GetRandom() {
      const { data: res } = await this.$http.get("blog/random");
      this.randList = res.data;
    },
    Gopage(items) {
      this.$router.push(`article/${items.id}`);
    },
  },
  mounted() {},
  created() {
    this.GetRandom();
  },
};
</script>

<style lang="less" scoped>
.random {
  width: 100%;
  max-width: 1566px;
  overflow: hidden;
  .container {
    margin-left: 15%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    width: 70%;
    .title_box {
      text-align: center;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .change {
        position: absolute;
        right: 1rem;
        cursor: pointer;
      }
    }
  }
  .list {
    width: 100%;
    padding-bottom: 1em;
    ul {
      list-style: none;
      li {
        padding: 1em;
        .rand_box {
          border-radius: 10px;
          overflow: hidden;
          width: 100%;
          position: relative;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .title {
            width: 100%;
            color: #fff;
            padding-left: 6px;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            bottom: 0;
          }
        }
        .rand_box:hover {
          filter: saturate(1.2);
          transform: scale(1.05);
        }
      }
    }
    ul:hover {
      .rand_box:not(:hover) {
        filter: blur(1px) brightness(0.8);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .title_box {
    height: 50px;
    width: 80%;
    max-width: 60em;
    font-size: larger;
  }
  .random {
    width: 100%;
    max-width: 1566px;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    margin-left: 5%;
    li {
      width: 25%;
      .rand_box {
        min-height: 220px;
        .title {
          line-height: 40px;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .title_box {
    height: 40px;
    width: 100%;
  }
  .random {
    width: 100%;
    ul {
      display: grid;
      grid-template-columns: 50% 50%;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0;
      li {
        box-sizing: border-box;
        width: 100%;
        .rand_box {
          min-height: 100px;
          .title {
            line-height: 30px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
