<!--
 * @Author: wankong
 * @Desc: 
 * @Date: 2021-09-19 09:27:06
 * @LastEditTime: 2021-12-05 15:59:51
-->
<template>
  <!-- 备案信息等 -->
  <MMCard>
    <template slot="content">
      <a href="https://beian.miit.gov.cn/" target="_blank">{{ icp }}</a>
      <br />
      <span :id="'cnzz_stat_icon_' + cnzzid"></span>
      <br />
      <a href="#" target="_blank">Design By wankong</a>
      <br />
      <a href="http://www.wankong.top" target="_blank"
        >Copyright © wankong.top 2016-2021.All rights reseved
      </a>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";
export default {
  components: {
    MMCard,
  },
  name: "CopyRight",
  data() {
    return {
      icp: this.$store.state.config.web_beian,
      title: "晚空'小站",
      cnzzid: this.$store.state.config.web_cnzz,
    };
  },
  mounted() {
    let sc = document.createElement("script");
    let span = document.querySelector(`#cnzz_stat_icon_${this.cnzzid}`);
    sc.type = "text/javascript";
    sc.src = `//s23.cnzz.com/z_stat.php?id=${this.cnzzid}&online=2`;
    span.appendChild(sc);
  },
};
</script>
<style lang="less" scoped>
</style>
