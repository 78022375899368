<!--
 * @Author: wankong
 * @Date: 2021-09-19 09:59:13
 * @LastEditTime: 2021-11-26 16:47:43
-->
<template>
  <!-- 文章列表 -->
  <div class="art_list">
    <QueryCard
      v-if="query.keyword || query.cateid || query.tags"
      @click="clearFilter()"
    />

    <template v-if="total == 0">
      <NoItem :tips="noItemTip" />
    </template>
    <template v-else>
      <ArtItem v-for="art in artList" :key="art.title" :art="art" />
      <Pagepaging
        :page-size="query.limit"
        :current-page="query.pages"
        :total="total"
        @page-change="pageChange"
    /></template>
  </div>
</template>
<script>
import ArtItem from "@/components/home/ArtItem";

import NoItem from "@/components/common/NoItem";
import Pagepaging from "@/components/home/Pagepaging";
import QueryCard from "./QueryCard.vue";
export default {
  components: {
    ArtItem,
    NoItem,
    Pagepaging,
    QueryCard,
  },
  name: "ArtList",
  data() {
    return {
      noItemTip: "当前搜索条件下没有结果哦！换个条件试试吧！",
      query: {
        limit: 5,
        pages: 1,
        keyword: "",
        cateid: "",
        tags: "",
      },
      total: 0,
      artList: [],
    };
  },
  watch: {
    $route: "initQuery",
  },

  methods: {
    async GetInfo() {
      // ajax({
      //   url:""
      //   method:"post",
      //   data:{}
      //   success(res)
      //   {

      //   }
      // })
      // axios
      const { data: res } = await this.$http.get("blog", {
        params: this.query,
      });
      this.artList = res.data.lists;
      this.total = res.data.total;
    },
    pageChange(nowPage) {
      this.query.pages = nowPage;
      this.GetInfo();
    },

    initQuery() {
      this.query.tags = this.$route.query.tags;
      this.query.keyword = this.$route.query.keyword;
      this.query.cateid = this.$route.query.cateid;
      this.GetInfo();
    },
    clearFilter() {
      this.query.tags = this.query.keyword = this.query.cateid = undefined;
      this.GetInfo();
    },
  },
  created() {
    this.initQuery();
  },
};
</script>
<style lang="less" scoped>
.art_list {
  width: 100%;
}
</style>
