<!--
 * @Author: wankong
 * @Date: 2021-10-04 23:27:07
 * @LastEditTime: 2021-10-05 11:39:22
-->
<template>
  <!-- 清除查询条件-->
  <MMCard :showTitle="false">
    <template slot="content">
      <div class="btn_box">
        <button @click="clearData()">清除筛选条件</button>
      </div>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card";
export default {
  components: {
    MMCard,
  },
  name: "QueryCard",
  methods: {
    clearData() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="less" scoped>
.btn_box {
  text-align: center;
  button {
    border: none;
    border-radius: 10px;
    padding: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background: #ffffff;
    color: #9b98f0;
    cursor: pointer;
    border: solid 1px #9b98f0;
  }
  button:hover {
    background: #9b98f0;
    color: #fff;
  }
}
</style>
