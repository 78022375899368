<template>
  <!-- 卡片视图，为其他的小组件做背景 -->
  <div
    class="card"
    :style="'background: rgba(255, 255, 255, ' + opacity + ');'"
  >
    <div class="head" v-if="showTitle">{{ title }}</div>
    <slot name="content"></slot>
  </div>
</template>
<script>
export default {
  name: "MMCard",
  props: {
    showTitle: {
      type: Boolean,
      default: true,
      require: false,
    },
    // 透明度
    opacity: {
      type: String,
      default: "0.4",
      require: false,
    },
    title: String,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.card {
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 6px;
  .head {
    font-size: 18px;
    color: #282828;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
    padding-bottom: 15px;
    margin-bottom: 25px;
    position: relative;
    transition: all ease-in-out 0.5s;
  }
  .head:after {
    content: "";
    background-color: #282828;
    left: 1px;
    width: 50px;
    height: 2px;
    bottom: -2px;
    position: absolute;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
  }
}
.card:hover {
  box-shadow: 2px 0px 10px 1px #ddd;
  .head::after {
    width: 100px;
  }
}
</style>
