/*
 * @Author: wankong
 * @Date: 2021-09-20 11:58:33
 * @LastEditTime: 2021-12-24 15:53:27
 */
import Vue from "vue";

import axios from "axios";
const baseURL = "spring.wankong.top";

// const baseURL = "localhost:9090"
axios.defaults.baseURL = "http://" + baseURL + "/api/";

Vue.prototype.mmconfig = {};
Vue.prototype.$http = axios;
Vue.prototype.BaseUrl = baseURL;
