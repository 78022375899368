<!--
 * @Author: wankong
 * @Date: 2021-09-07 21:27:49
 * @LastEditTime: 2021-11-19 14:26:31
-->
<template>
  <!-- 首页文章列表 -->
  <div class="main_article">
    <div class="left">
      <Tips :content="tips" />
      <ArtList />
      <CopyRight class="copyright" style="display: none" />
    </div>
    <div class="right">
      <ArtRightList />
    </div>
  </div>
</template>

<script>
import ArtList from "@/components/home/ArtList.vue";

import Tips from "@/components/home/Tips.vue";
import ArtRightList from "@/components/home/ArtRightList.vue";

import CopyRight from "@/components/common/CopyRight";

export default {
  components: {
    ArtRightList,
    CopyRight,
    Tips,
    ArtList,
  },
  name: "HomeContent",
  data() {
    return {
      tips: this.$store.state.config.web_tips,
      query: {
        cate: "",
        limit: "",
        pages: "",
        keyword: "",
      },
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.main_article {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

  .left {
    width: 60%;
  }
  .right {
    margin-left: 5%;
    width: 30%;
  }
}

@media screen and (min-width: 1366px) {
  .main_article {
    max-width: 1366px;
  }
}

@media screen and (max-width: 768px) {
  .main_article {
    width: 100%;
    .left {
      width: 95%;
      .copyright {
        display: block !important;
      }
    }
    .right {
      display: none;
    }
  }
}
</style>