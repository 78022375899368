<template>
  <!-- 分页小组件 -->
  <MMCard :showTitle="false" v-if="pages.length > 1">
    <template slot="content">
      <ul class="content">
        <li class="btn" @click="pageHandle" v-if="nowPage != 1">上一页</li>
        <li
          class="btn"
          :class="nowPage == page ? 'active' : ''"
          v-for="page in pages"
          @click="GoPage(page)"
          :key="page"
        >
          {{ page }}
        </li>
        <li class="btn" @click="pageNext" v-if="nowPage != pages.length">
          下一页
        </li>
      </ul>
    </template>
  </MMCard>
  <MMCard :showTitle="false" v-else>
    <template slot="content">
      <p>没有更多内容了。。。。</p>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card";
export default {
  name: "Pagepaging",
  components: {
    MMCard,
  },
  props: {
    pageSize: {
      type: [Number, String],
      require: false,
      default: 5,
    },
    currentPage: {
      type: [Number, String],
      require: false,
      default: 1,
    },
    total: {
      type: [Number, String],
      require: false,
      default: 0,
    },
  },
  data() {
    return {
      pages: [1],
      nowPage: this.currentPage,
    };
  },

  mounted() {
    this.init();
  },
  watch: {
    total: function () {
      this.init();
    },
  },
  methods: {
    init() {
      let i = 2;
      this.pages = [1];
      let total = this.total;
      while (total - this.pageSize > 0) {
        this.pages.push(i);
        i++;
        total = total - this.pageSize;
      }
    },
    // 前一页
    pageHandle() {
      if (this.nowPage == 1) {
        alert("已经是第一页了-。-");
      } else {
        this.nowPage = this.nowPage - 1;
        this.sendEmit();
      }
    },
    // 前往指定页
    GoPage(pid) {
      this.nowPage = pid;
      this.sendEmit();
    },
    // 下一页
    pageNext() {
      if (this.nowPage == this.pages.length) {
        alert("已经是最后一页了-。-");
      } else {
        this.nowPage = this.nowPage + 1;
        this.sendEmit();
      }
    },
    sendEmit() {
      this.$emit("page-change", this.nowPage);
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  list-style: none;
  .btn {
    cursor: pointer;
    padding: 0.7rem 1rem;
    margin: 0.1rem;
    font-size: 16px;
    line-height: 1.3rem;
    user-select: none;
    border-radius: 6px;
    background: #fff;
  }
  .active {
    background: #91bebb;
    color: #fff;
  }
  .btn:hover {
    background: #91bebb;
    color: #fff;
  }
}
</style>
