<!--
 * @Author: wankong
 * @Date: 2021-09-09 21:27:35
 * @LastEditTime: 2021-10-06 15:43:35
-->
<template>
  <!-- 返回顶部 -->
  <div :class="backCss" @click="backTop" title="返回顶部">
    <i class="bi bi-arrow-up-circle"></i>
  </div>
</template>
<script>
export default {
  name: "BackTop",
  data() {
    return {
      backCss: "back",
    };
  },
  methods: {
    ListenScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 600) {
        this.backCss = "back show";
      } else {
        this.backCss = "back";
      }
    },
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.pageYOffset = 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.ListenScroll);
  },
};
</script>
<style lang="less" scoped>
.back {
  cursor: pointer;
  position: fixed;
  height: 80px;
  width: 80px;
  right: 80px;
  bottom: 80px;
  font-size: 50px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.5s;
  display: none;
  i {
    color: #07c160;
  }
}
.back:active {
  animation: shakes 1s ease-in-out infinite;
  background: rgba(255, 255, 255, 0.6);
}

@keyframes shakes {
  0% {
    height: 78px;
    width: 78px;
  }
  25% {
    height: 76px;
    width: 76px;
  }
  50% {
    height: 74px;
    width: 74px;
  }
  75% {
    height: 76px;
    width: 76px;
  }
  100% {
    height: 80px;
    width: 80px;
  }
}
.show {
  display: flex;
}
@media screen and (max-width: 768px) {
  .back {
    width: 40px !important;
    height: 40px !important;
    font-size: 30px !important;
    bottom: 20px !important;
    right: 20px !important;
  }
}
</style>
