/*
 * @Author: wankong
 * @Date: 2021-09-04 15:38:24
 * @LastEditTime: 2021-11-03 18:33:22
 */
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import './common/common.css'
// use


import './common/axios.setting.js'

import './common/filter.js'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    config: {},
  },
  mutations: {
    setConfig: (state, payload) => {
      state.config = payload.config
    }
  }
})


Vue.config.productionTip = false

Vue.directive('title', { //单个修改标题
  inserted: function (
    el
  ) {
    const title = el.dataset.title == "" ? store.state.config.web_title : el.dataset.title + "——" + store.state.config.web_title;
    document.title = title
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')