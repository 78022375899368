<template>
  <!-- 当前页面的二维码-->
  <MMCard title="手机查看">
    <template slot="content">
      <img :src="nowUrl" alt="" />
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card";
export default {
  components: {
    MMCard,
  },
  name: "NowQrcode",
  data() {
    return {
      nowUrl: "",
    };
  },
  methods: {
    GetNowUrl() {
      this.nowUrl =
        "https://sou.mmifx.com/index.php/index/qrcode/?text=" +
        window.location.href;
    },
  },
  mounted() {
    this.GetNowUrl();
  },
};
</script>
<style lang="less" scoped>

img {
  width: 50%;
  height: 50%;
}
</style>
