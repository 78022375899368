<!--
 * @Author: wankong
 * @Date: 2021-09-04 15:38:24
 * @LastEditTime: 2021-11-19 14:31:04
-->
<template>
  <div id="app">
    <transition name="fade" v-if="loading">
      <router-view />
    </transition>
    <transition v-else>
      <div class="load">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    mmconfig: function () {
      return this.$store.state.config;
    },
  },
  methods: {
    async getConfig() {
      const { data: res } = await this.$http.get("web/config");
      this.$store.commit({ type: "setConfig", config: res.data });
      this.loading = true;
    },
  },
  created() {
    this.getConfig();
  },
};
</script>
<style lang="less">
#app {
  min-width: 100%;
  min-height: 100vh;
  position: relative;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.load {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    display: inline-block;
    margin: 4px;
    width: 8px;
    height: 100%;
    border-radius: 4px;
    background: lightgreen;
    animation: load 1s ease infinite;
  }
  @keyframes load {
    0%,
    100% {
      height: 40px;
      background: lightgreen;
    }
    50% {
      height: 70px;
      margin-top: -15px;
      margin-bottom: -15px;
      background: lightblue;
    }
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.4s;
  }
  span:nth-child(4) {
    animation-delay: 0.6s;
  }
  span:nth-child(5) {
    animation-delay: 0.8s;
  }
}
</style>
