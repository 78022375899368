<!--
 * @Author: wankong
 * @Desc: 
 * @Date: 2021-09-04 15:38:24
 * @LastEditTime: 2021-11-19 14:19:42
-->
<template>
  <div class="home">
    <div v-title data-title=""></div>
    <MHeader />
    <Top />
    <div class="main_back" :style="'background-image: url(' + backImg + ')'">
      <Random />
      <HomeContent />
    </div>
    <BackTop />
  </div>
</template>

<script>
// @ is an alias to /src

import Top from "@/components/common/Top.vue";

import Random from "@/components/common/Random.vue";
import HomeContent from "@/components/home/HomeContent.vue";
import BackTop from "@/components/common/BackTop.vue";
import MHeader from '../components/common/Header.vue';
export default {
  name: "Home",
  components: {
    MHeader,
    Top,
    Random,
    HomeContent,
    BackTop
  },
  data() {
    return {
      backImg: this.$store.state.config.web_bgimg,
    };
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
}
.main_back {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
