<template>
  <div :class="topClass">
    <div class="Header" v-if="hiddenTab">
      <div class="logo" @click="BackHome">
        <img :src="logoImg" />
      </div>
      <div class="cate">
        <ul>
          <li v-for="cate in cateList" :key="cate.id">
            <div @click="GoCate(cate)" :title="cate.catedesc">
              {{ cate.catename }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="shadow" v-if="hiddenTab" @click="closeTabbar()"></div>

    <div class="mobile-title" :class="hiddenTab ? 'hidden' : ''">
      <i class="bi bi-justify-left" @click="showTabBar()"></i>
      <template v-if="showSearch">
        <div class="searchBar">
          <input placeholder="输入你想搜的关键字" v-model="searchkey" />
          <i class="bi bi-search" @click="submitSearch()"></i>
        </div>
        <i class="bi bi-x-lg" @click="hiddenSearch()"></i
      ></template>
      <template v-else>
        <img :src="logoImg" @click="BackHome" />
        <i class="bi bi-search" @click="showSearchBar()"></i
      ></template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data() {
    return {
      logoImg: "//www.mmifx.com/logo.png",
      topClass: "bg",
      cateList: [],
      hiddenTab: true,
      searchkey: "",
      showSearch: false,
    };
  },
  methods: {
    ListenScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 200) {
        this.topClass = "bg noTop";
      } else {
        this.topClass = "bg";
      }
    },
    BackHome() {
      this.$router.push("/");
    },
    async getCateList() {
      const { data: res } = await this.$http.get("cate");
      this.cateList = res.data;
    },
    GoCate(cate) {
      this.$router.push({ path: "/", query: { cateid: cate.id } });
    },
    closeTabbar() {
      this.hiddenTab = false;
    },
    showTabBar() {
      this.hiddenTab = true;
    },
    initTab() {
      if (document.body.clientWidth < 768) {
        this.hiddenTab = false;
      } else {
        this.hiddenTab = true;
      }
    },
    hiddenSearch() {
      this.showSearch = false;
    },
    showSearchBar() {
      this.showSearch = true;
    },
    submitSearch() {
      this.showSearch = false;
      this.$router.push({ path: "/", query: { keyword: this.searchkey } });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.ListenScroll);
    this.getCateList();
    this.initTab();
    window.addEventListener("resize", this.initTab);
  },
};
</script>

<style scoped lang="less">
.bg {
  overflow: hidden;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 8%);
}
.Header {
  display: flex;
  .logo {
    padding: 10px;
    height: 60px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cate {
    ul {
      height: 100%;
      list-style: none;
      display: flex;
      li {
        font-size: large;
        cursor: pointer;
        color: #404040;
        margin-left: 18px;
        transition: none;
        transform: all .1s linear;
      }

      li:hover {
        color: #409eff;
      }
    }
  }
}
.noTop {
  height: 0px !important;
}
@keyframes topScroll {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.6);
  }
  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}

@media screen and (min-width: 768px) {
  .bg {
    width: 100%;
    height: 80px;
    right: 0;
    align-content: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    .shadow {
      display: none;
    }
  }
  .Header {
    width: 1000px;
    align-content: center;
    justify-content: space-between;
    .logo {
      width: 200px;
    }
    .cate {
      ul {
        justify-content: center;
        align-items: center;
        li {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .mobile-title {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .bg {
    width: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    .shadow {
      flex: 1;
      height: 100vh;
    }
  }
  .Header {
    background-color: rgba(255, 255, 255, 1);
    width: 200px;
    overflow: hidden;
    flex-direction: column;
    align-content: center;
    .logo {
      width: 150px;
      margin-left: 25px;
    }
    .cate {
      ul {
        flex-direction: column;
        li {
          margin: 10px;
        }
      }
    }
  }
  .mobile-title {
    margin-left: 5%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    .searchBar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      input {
        border-radius: 10px;
        margin: 6px;
        margin-left: 1rem;
        box-sizing: border-box;
        border: none;
        background: none;
        color: #666;
        background: rgba(255, 255, 255, 0.6);
        padding: 10px;
        border: 1px solid #eaeaea;
        overflow: hidden;
      }
      .bi-search {
        padding: 10px;
      }
      .bi-search:hover {
        color: #409eff;
      }
    }
    img {
      width: 200px;
    }
  }
  .hidden {
    display: none;
  }
}
</style>
