<!--
 * @Author: wankong
 * @Date: 2021-09-09 21:11:25
 * @LastEditTime: 2021-10-18 22:00:21
-->
<template>
  <!-- 友情链接 -->
  <MMCard title="友情链接">
    <template slot="content">
      <div class="linksList">
        <a
          :href="link.url"
          :title="link.desc"
          class="link"
          target="_blank"
          v-for="link in linksList"
          :key="link.id"
        >
          {{ link.name }}
        </a>
      </div>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";
export default {
  components: {
    MMCard,
  },
  name: "Links",
  data() {
    return {
      linksList: [],
    };
  },
  methods: {
    async getLinkList() {
      const { data: res } = await this.$http.get("link");
      this.linksList = res.data;
    },
  },
  mounted() {
    this.getLinkList();
  },
};
</script>
<style lang="less" scoped>
.linksList {
  .link {
    margin-bottom: 10px;
    margin-right: 10px;
    white-space: wrap;
    display: inline-block;
    transition: all ease-in-out 0.3s;
    position: relative;
  }
  .link::after {
    content: "";
    background-color: #282828;
    left: 1px;
    width: 0;
    height: 1px;
    bottom: -2px;
    position: absolute;
    transition: all ease-in-out 0.5s;
  }

  .link:hover:after {
    content: "";
    background-color: #409eff;
    left: 1px;
    width: 80%;
    height: 1px;
    bottom: -2px;
    position: absolute;
  }
}
</style>
