<!--
 * @Author: wankong
 * @Date: 2021-09-09 19:15:40
 * @LastEditTime: 2021-10-18 22:00:13
-->
<template>
  <!-- 最热文章排行的前5个 -->
  <MMCard title="热度排行" class="art_hots">
    <template v-slot:content>
      <div class="art_box">
        <div class="art" v-for="art in hotsList" :key="art.id">
          <img :src="art.image" alt="" />
          <div class="title">
            {{ art.title }}
            <p class="btn" @click="GoPage(art.id)">查看文章</p>
          </div>
        </div>
      </div>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";
export default {
  components: {
    MMCard,
  },
  name: "ArtHots",
  data() {
    return {
      hotsList: [],
    };
  },
  methods: {
    async GetHotsList() {
      const { data: res } = await this.$http.get("blog/hots");
      this.hotsList = res.data;
    },
    GoPage(aid) {
      this.$router.push(`article/${aid}`);
    },
  },
  created() {
    this.GetHotsList();
  },
};
</script>
<style lang="less" scoped>
.art_hots {
  .art_box {
    .art {
      margin-top: 10px;
      width: 100%;
      height: 150px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        border-radius: 6px;
        width: 100%;
        height: 150px;
        position: absolute;
        filter: brightness(0.8);
      }

      .title {
        text-align: center;
        z-index: 10;
        color: #fff;
        p {
          cursor: pointer;
          margin-top: 10px;
          padding: 8px 16px;
          line-height: 1;
          border-radius: 30px;
          font-size: 12px;
          border: solid 1px #fff;
        }
      }
    }
    .art:hover {
      img {
        filter: brightness(0.6);
      }
      p {
        color: #000;
        background-color: #fff;
      }
    }
  }
}
</style>
