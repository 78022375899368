<!--
 * @Author: wankong
 * @Date: 2021-09-09 19:35:48
 * @LastEditTime: 2021-10-22 10:46:07
-->
<template>
  <!-- 首页右边的侧边工具集 -->
  <div class="Card_list">
    <Search />
    <ArtHots />
    <NowBack :backImg="backImg" />
    <Tags />
    <NowQrcode />
    <Links />
    <Todo />
    <CopyRight />
  </div>
</template>
<script>
import ArtHots from "@/components/home/artRight/ArtHots";
import Links from "@/components/home/artRight/Links";
import NowBack from "@/components/home/artRight/NowBack";
import Todo from "@/components/home/artRight/Todo";
import NowQrcode from "@/components/common/NowQrCode";
import Tags from "@/components/home/artRight/Tags";
import Search from "@/components/home/artRight/Search";
import CopyRight from "@/components/common/CopyRight";
export default {
  components: {
    ArtHots,
    NowBack,
    Search,
    NowQrcode,
    Tags,
    Links,
    Todo,
    CopyRight,
  },
  name: "ArtRightList",
  data() {
    return {
      backImg:  this.$store.state.config.web_bgimg,
    };
  },
};
</script>
<style lang="less" scoped>
</style>
