<!--
 * @Author: wankong
 * @Desc: 
 * @Date: 2021-09-09 19:37:21
 * @LastEditTime: 2021-10-19 19:02:48
-->
<template>
  <!-- 当前背景图片的下载地址 -->
  <MMCard title="当前背景图" class="now_back">
    <template slot="content">
      <button @click="_download" title="下载当前使用的背景图片">
        点击下载
      </button>

      <button @click="_copyURL" title="复制当前使用的背景地址">复制地址</button>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card.vue";
export default {
  props: {
    backImg: String,
  },
  components: {
    MMCard,
  },
  name: "NowBack",
  data() {
    return {};
  },
  methods: {
    _download() {
      var a = document.createElement("a");
      a.download = "blogBack";
      // 设置图片地址
      a.href = this.backImg;
      a.click();
    },
    _copyURL() {
      let einput = document.createElement("input");
      einput.value = this.backImg;
      document.body.appendChild(einput);
      einput.select();
      let copyText = document.execCommand("Copy");
      if (copyText) {
        alert("复制成功！");
      } else {
        alert("复制失败！");
      }
      einput.style.display = "none";
    },
  },
};
</script>
<style lang="less" scoped>
button {
  border: none;
  background: none;
  cursor: pointer;
  margin: 10px;
  transition: all ease-in-out 0.3s;
}
button:nth-child(2) {
  float: right;
}
button:hover {
  color: #fff;
}
</style>
