<!--
 * @Author: wankong
 * @Date: 2021-09-22 22:30:55
 * @LastEditTime: 2021-10-04 18:16:53
-->
<template>
  <!-- 标签列表， 5个为一组 -->
  <div class="tag_list">
    <a class="tag" v-for="tag in tags" :key="tag.id" @click="GoTag(tag)">
      {{ tag.tagname }}
    </a>
  </div>
</template>

<script>
export default {
  name: "TagList",
  props: {
    tags: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    GoTag(tag) {
      this.$router.push({ path: `/`, query: { tags: tag.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.tag_list {
  text-indent: 0;
  width: 100%;
  margin-bottom: 10px;
  .tag {
    padding: 4px 8px;
    background-color: #19b5fe;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    margin: 3px 5px 3px 0;
    border-radius: 10px;
    display: inline-block;
    margin-bottom: 6px;
  }
  .tag:nth-child(5n) {
    background-color: #4a4a4a;
  }
  .tag:nth-child(5n + 1) {
    background-color: #ff5e5c;
  }
  .tag:nth-child(5n + 2) {
    background-color: #ffbb50;
  }
  .tag:nth-child(5n + 3) {
    background-color: #1ac756;
  }
  .tag:nth-child(5n + 4) {
    background-color: #19b5fe;
  }
  .tag:hover {
    background-color: #1b1b1b;
    color: #fff;
  }
}
</style>